import Tick from '@pqina/flip';
import '@pqina/flip/dist/flip.min.css';
import './clock.css';

export default function initClock(firstDay) {
  Tick.DOM.create(document.querySelector('.tick'), {
    didInit: function (tick) {
      const locale = {
        YEAR_PLURAL: '年',
        YEAR_SINGULAR: '年',
        MONTH_PLURAL: '月',
        MONTH_SINGULAR: '月',
        WEEK_PLURAL: '週',
        WEEK_SINGULAR: '週',
        DAY_PLURAL: '天',
        DAY_SINGULAR: '天',
        HOUR_PLURAL: '時',
        HOUR_SINGULAR: '時',
        MINUTE_PLURAL: '分',
        MINUTE_SINGULAR: '分',
        SECOND_PLURAL: '秒',
        SECOND_SINGULAR: '秒',
        MILLISECOND_PLURAL: '毫秒',
        MILLISECOND_SINGULAR: '毫秒'
      }
      for (const [k, v] of Object.entries(locale)) {
        tick.setConstant(k, v);
      }
      
      const counter = Tick.count.up(firstDay , { format: ['y','M','d','h','m','s'] });
      counter.onupdate = function (value) {
        tick.value = value;
      };
    },
  });
}
