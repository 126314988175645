import {
  checkAnniversary,
  checkEvery,
  checkEverySeconds,
  checkOn,
} from './checkEvent';
import { checkOnLunar } from './lunar';

const getZhNumber = (n) =>
  [, '一', '二', '三', '四', '五', '六', '七', '八', '九'][n];

export const FIRST_DAY = '2019-05-06T00:00:00.000+08:00';
export const CHECK_DAYS = 50;
export const TITLE = '包子們🐰😼已經甜蜜ㄌ';
export const DOCUMENT_TITLE = '💖BB';
export const checkers = [
  checkEvery(1111, null, 9999),
  checkEvery(100),
  checkOn('元旦玩鞭炮🧨', 1, 1),
  checkOn('大帥包的生日🥳', 2, 2),
  checkOn('情人節有愛人💕', 14, 2),
  checkOn('白色情人節🤍', 14, 3),
  checkOn('520💖', 20, 5),
  checkOn('花花的生日🥬', 17, 7),
  checkOn('可愛包的生日🎂', 14, 8),
  checkOn('萬聖節👻', 31, 10),
  checkOn('光棍不光棍🍭', 11, 11),
  checkOn('勞贖啪啾啪啾🐭', 27, 11),
  checkOn('聖誕節🎅', 25, 12),
  checkOnLunar('划龍舟🐲', 5, 5),
  checkOnLunar('可愛包的農曆生日🎂', 1, 7),
  checkOnLunar('七夕💞', 7, 7),
  checkOnLunar('吃月餅🥮', 15, 8),
  checkAnniversary(),
  checkEverySeconds(
    10000000,
    (seconds) => {
      let remainingSeconds = seconds;
      let result = '';
      let unit = Math.floor(seconds / 100000000);
      remainingSeconds -= unit * 100000000;
      if (unit !== 0) {
        result += `${getZhNumber(unit)}億`;
      }
      unit = remainingSeconds / 10000000;
      console.log(unit);
      if (unit !== 0) {
        result += `${getZhNumber(unit)}千萬`;
      }
      return `${result}秒紀念`;
    },
    990000000,
  ),
];
